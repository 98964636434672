import React, { Component } from 'react'
import './Footer.css';

export default class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <ul className="social-links">
                    <li><a href="https://github.com/bensjones" target="_blank"><i className="fa fa-github"></i></a></li>
                    <li><a href="https://www.linkedin.com/in/bensjones/" target="_blank"><i className="fa fa-linkedin"></i></a></li>
                    <li><a href="https://twitter.com/bensjones" target="_blank"><i className="fa fa-twitter"></i></a></li>
                </ul>
            </div>
        )
    }
}
